/**
 * @file queries.js is a file for all Customer mutations
 */
import { gql } from "@apollo/client";

/**
 * @const {Object} GET_ALL_CUSTOMERS - GraphQL query to get all customers.
 * 
 * @summary
 * - This query will return all customers.
 * 
 * @returns {Array} - An array of customer objects.
 */
export const GET_ALL_CUSTOMERS = gql`
    query getAllCustomers {
        getAllCustomers {
            _id
            name
            email
            phone
            address {
                line1
                line2
                city
                state
                zip
                country
            }
            createdAt
            updatedAt
        }
    }
`;

