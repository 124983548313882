/**
 * @file Pagination.js
 */

import React, { useState, useEffect } from "react";

import {
	Flex,
	Button, 
	Editable,
	EditableInput,
	EditablePreview,
} from "@chakra-ui/react";

import {
	ArrowBackIcon,
	ArrowForwardIcon,
} from "@chakra-ui/icons";

/**
 * @param {*} itemsPerPage
 * @param {*} totalItems
 * @param {*} paginate
 * @param {*} changeItemsPerPage
 * @returns - Pagination component
 */
const Pagination = ({ items, setItems }) => {

	const [itemsPerPage, setItemsPerPage] = useState(5);
	const [currentPage, setCurrentPage] = useState(1);
	const [currentItems, setCurrentItems] = useState([]); // [1, 2, 3, 4, 5
	
	const totalItems = items.length;

	let pageNumbers = [];

	for(let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
		pageNumbers.push(i);
	};

	useEffect(() => {
		
		if(items) {
			let startIndex = (currentPage - 1) * itemsPerPage;
			let endIndex = startIndex + itemsPerPage;
			//handle case where startIndex is greater than totalItems
			if(startIndex > totalItems) startIndex = totalItems - itemsPerPage;
			
			//Handle case where endIndex is greater than totalItems
			if(endIndex > totalItems) endIndex = totalItems;

			setCurrentItems(items.slice(startIndex, endIndex));
			let currPage = Math.ceil(endIndex / itemsPerPage);
			if(currPage === 0) currPage = 1;
			setCurrentPage(currPage);
		}
	}, [items, itemsPerPage]);

	useEffect(() => {
		// if(currentItems && currentItems.length > 0){
		// 	setItems(currentItems);
		// };
		setItems(currentItems);
	}, [currentItems]);

	const paginate = (pageNumber) => {
		const startIndex = (pageNumber - 1) * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;
		setCurrentItems(items.slice(startIndex, endIndex));
	};

	const handleClick = (event, number) => {
		event.preventDefault();

		setCurrentPage(number);
		paginate(number);
	};

	const handleItemsPerPageChange = (numItems) => {
		numItems = parseInt(numItems); 
		setItemsPerPage(numItems);
	};

	// Only display 5 page options at a maximum
	if (pageNumbers.length > 5) {
		const maxLeft = currentPage - 2;
		const maxRight = currentPage + 2;
		if (maxLeft < 1) {
			pageNumbers = [1, 2, 3, 4, 5];
		} else if (maxRight > pageNumbers.length) {
			pageNumbers = [
				pageNumbers.length - 4,
				pageNumbers.length - 3,
				pageNumbers.length - 2,
				pageNumbers.length - 1,
				pageNumbers.length,
			];
		} else {
			pageNumbers = [
				currentPage - 2,
				currentPage - 1,
				currentPage,
				currentPage + 1,
				currentPage + 2,
			];
		}
	}

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			// mb={4}
			py={4}
		>
			<Button
				variant="primary2"
				isDisabled={currentPage === 1}
				onClick={(event) => handleClick(event, currentPage - 1)}
			>
				<ArrowBackIcon/>
			</Button>
			{
				pageNumbers.map(number => (
					<Button
						key={number}
						variant="outline"
						mx="2px"
						//Highlight the current page
						bg={currentPage === number ? "tf_red" : ""}
						color={currentPage === number ? "white" : ""}
						onClick={(event) => handleClick(event, number)}
					>
						{number}
					</Button>
				))
			}
			<Button
				variant="outline"
				isDisabled={currentPage === pageNumbers.length}
				onClick={(event) => handleClick(event, currentPage + 1)}
			>
				<ArrowForwardIcon/>
			</Button>
			<Editable
				ml={4}
				px="0.9rem"
				border="1px dashed gray"
				boxShadow="xl"
				rounded="md"
				value={itemsPerPage}
				defaultValue={itemsPerPage}
			>
				<EditablePreview />
				<EditableInput 
					value={itemsPerPage}
					type="number"
					onChange={(event) => {
						try{
							let value = parseInt(event.target.value);
							if(isNaN(value)) value = 1;
							if(value < 1) value = 1;
							handleItemsPerPageChange(value);
						}
						catch(err) {
							handleItemsPerPageChange(1);
						}
					}}
				/>
			</Editable>
		</Flex>
	);
};

export default Pagination;
