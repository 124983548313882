/**
 * @file CreateInvoice.js
 * 
 * @author Steven Secreti
 */
import React, { useState, useEffect } from "react";

import { Checkbox, CheckboxGroup, Radio, RadioGroup } from "@chakra-ui/react";

import {
	Alert,
	Button,
	IconButton,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	ModalFooter,
	Stack,
	AlertIcon,
	AlertDescription,
	Collapse,
	Flex,
	Text
} from "@chakra-ui/react";

import Select from "react-select";

import { DeleteIcon } from "@chakra-ui/icons";

import useCreateInvoice from "hooks/Invoice/mutations/useCreateInvoice";

import useGetAllCustomers from "hooks/Customer/queries/useGetAllCustomers";

import useAdminSearchProducts from "hooks/Product/queries/useAdminSearchProducts";

import LoadingErrorDataWrapper from "wrappers/LoadingErrorDataWrapper";

import { TextCell, TableCell } from "components/Admin/Dashboard/components/TableCell";
import BackendPagination from "components/Admin/Dashboard/components/BackendPagination";

import { PDFDropzone } from "Blueprints/Dropzones/PDFDropzone";
import useUploadPDF from "hooks/useUploadPDF";

/**
 * @component CreateInvoice
 * 
 * @returns
 */
const CreateInvoice = ({ isOpen, onClose, invoicesRefetch }) => {

	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(5);
	const [searchTerm, setSearchTerm] = useState("");

	const { loading, error, data: customers } = useGetAllCustomers();
	const { loading: productsLoading, error: productsError, data: paginatedProducts } = useAdminSearchProducts({offset, limit, term: searchTerm});

	const [createLoading, setCreateLoading] = useState(false);
	const [createError, setCreateError] = useState(null);
	const [showSelectFromProducts, setShowSelectFromProducts] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [pdf, setPDF] = useState(null);
	
	const handleSetPDF = (pdfFile) => {
		setPDF(pdfFile);
	};

	const uploadPDF = useUploadPDF();

	useEffect(() => {	
		if(selectedProduct){
			//Add a new item to the invoice based on the selectedProduct.sku, selectedProduct.price
			setInput({
				...input,
				items: [
					...input.items,
					{
						item: selectedProduct.name,
						quantity: 1,
						rate: selectedProduct.price ? selectedProduct.price : 0,
						isTaxable: true,
					},
				],
			});
			setShowSelectFromProducts(false);
			setSelectedProduct(null);
		};
	}, [selectedProduct]);

	const [input, setInput] = useState({
		customer: "",
		service_date: "",
		service_description: "",
		total_amount: "",
		items: [],
		discount: 0,
		includeTransactionFee: true,
	});

	const [customerType, setCustomerType] = useState("existing");

	const handleUpdateCustomerType = (customerType) => {
		setCustomerType(customerType);
		setInput(prevInput => ({
			...prevInput,
			customer: "",
		}));
	};

	const handleUpdateInput = (e) => {
		const { name, value } = e.target;
		setInput(prevInput => ({
			...prevInput,
			[name]: value,
		}));
	};

	const handleTransactionFeeChange = (e) => {
		const isChecked = e.target.checked;
		setInput(prevInput => ({
			...prevInput,
			includeTransactionFee: isChecked,
		}));
	};

	const handleTaxableChange = (isChecked, itemIndex) => {
		const newItems = [...input.items];
		newItems[itemIndex].isTaxable = isChecked;
		setInput(prevInput => ({ ...prevInput, items: newItems }));
	};

	const handleAddNewItem = () => {
		setInput(prevInput => ({
			...prevInput,
			items: [
				...input.items,
				{
					item: "",
					quantity: 0,
					rate: 0,
					isTaxable: true,
				},
			],
		}));
	};

	const handleUpdateItem = (e, index) => {
		const { name, value } = e.target;
		const items = [...input.items];
		items[index][name] = value;
		setInput(prevInput => ({
			...prevInput,
			items,
		}));
	};

	const handleRemoveItem = (index) => {
		const items = [...input.items];
		items.splice(index, 1);
		setInput(prevInput => ({
			...prevInput,
			items,
		}));
	};

	const handleClose = () => {
		resetStates();
		onClose();
	};

	const resetStates = () => {
		setCreateLoading(false);
		setCreateError(null);
		setShowSelectFromProducts(false);
		setSelectedProduct(null);
		setPDF(null);
		setInput({
			customer: "",
			service_date: "",
			service_description: "",
			total_amount: "",
			items: [],
			discount: 0,
			includeTransactionFee: true,
		});
	};

	const { createInvoice } = useCreateInvoice();

	const handleCreateInvoice = async (e) => {
		e.preventDefault();

		setCreateError(null);
		setCreateLoading(true);

		let pdf_url;
		if (pdf) {
			try {
				pdf_url = await uploadPDF(pdf);
			} catch (error) {
				setCreateError("Unexpected error while uploading PDF. Please try again.");
				setCreateLoading(false);
				return;
			}
		}

		const items = [...input.items];
		let total_amount = 0;
		items.forEach((item) => {
			item.amount = parseFloat(item.quantity * item.rate);
			item.quantity = parseInt(item.quantity);
			item.rate = parseFloat(item.rate);
			total_amount += item.amount;
		});
		total_amount -= (total_amount * input.discount) / 100;
		total_amount = parseFloat(total_amount.toFixed(2));

		let discount = parseFloat(input.discount);

		const createInvoiceInput = {
			...input,
			total_amount,
			items,
			discount,
		};

		if (pdf_url) {
			createInvoiceInput.pdf = pdf_url;
		}

		const { data, error } = await createInvoice(createInvoiceInput);

		if(error){
			setCreateError(error.message);
			setCreateLoading(false);
			return;
		}
		if(data){
			await invoicesRefetch();
			setCreateLoading(false);
			handleClose();
			return;
		}
	};

	const customerOptions = customers?.map(customer => ({
		value: customer._id,
		label: `${customer.name}`
	}));

	return (
		<Modal 
			isOpen={isOpen} 
			onClose={handleClose}
			size="full"
			scrollBehavior="outside"
			overflowX="hidden"
		>
			<ModalOverlay />
			<ModalContent
				as="form"
				onSubmit={handleCreateInvoice}
				padding="4.3rem"
			>
				<ModalHeader
					fontSize="2xl"
					fontWeight="bold"
					textAlign="center"
				>
					Create Invoice
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<LoadingErrorDataWrapper
						loading={loading || productsLoading}
						error={error?.message || productsError?.message}
						data={customers}
					>
						<Stack
							spacing={4}
						>
							{/* Customer & Service Date */}
							<Stack
								direction={{
									base: "column",
									md: "row",
								}}
							>
								<FormControl>
									<Stack
										direction="row"
									>
										<FormLabel
											direction="row"
										>
											{customerType === "existing" ? "Select Customer" : "Customer Email"}
										</FormLabel>
										<RadioGroup
											onChange={handleUpdateCustomerType}
											value={customerType}
										>
											<Stack direction="row">
												<Radio value="existing">Existing</Radio>
												<Radio value="new">New</Radio>
											</Stack>
										</RadioGroup>	
									</Stack>
									{
										customerType === "existing" ?
											<Select
												placeholder="Select a customer..."
												isSearchable
												name="customer"
												value={customerOptions?.find(option => option.value === input.customer)}
												onChange={(selectedOption) => {
													handleUpdateInput({
														target: {
															name: "customer",
															value: selectedOption.value,
														}
													});
												}}
												borderRadius="md"
												borderWidth="1px"
												borderColor="gray.400"
												boxShadow="md"
												options={
													customers?.map((customer) => ({
														value: customer._id,
														label: `${customer.name}`,
													}))
												}
												required={true}
											/>
											:
											<Input
												placeholder="Enter new customer email..."
												name="customer"
												value={input.customer}
												onChange={handleUpdateInput}
												borderRadius="md"
												borderWidth="1px"
												borderColor="gray.400"
												boxShadow="md"
											/>
									}
								</FormControl>
								<FormControl 
									mt={4}
									isRequired
								>
									<FormLabel>Service Date</FormLabel>
									<Input
										type="date"
										name="service_date"
										value={input.service_date}
										onChange={handleUpdateInput}
										borderRadius="md"
										borderWidth="1px"
										borderColor="gray.400"
										boxShadow="md"
									/>
								</FormControl>
							</Stack>

							{/* Service Description & Discount */}

							<Stack
								direction={{
									base: "column",
									md: "row",
								}}
								spacing={4}
								alignItems="flex-start"
								justifyContent="space-between"
							>
								<FormControl
									isRequired
								>
									<FormLabel>Service Description</FormLabel>
									<Input
										name="service_description"
										value={input.service_description}
										onChange={handleUpdateInput}
										borderRadius="md"
										borderWidth="1px"
										borderColor="gray.400"
										boxShadow="md"
									/>
								</FormControl>
								<FormControl>
									<FormLabel>Discount (%)</FormLabel>
									<Input
										type="number"
										name="discount"
										value={input.discount}
										onChange={handleUpdateInput}
										borderRadius="md"
										borderWidth="1px"
										borderColor="gray.400"
										boxShadow="md"
									/>
								</FormControl>
								<FormControl>
									<FormLabel>Transaction Fee</FormLabel>
									<Checkbox
										name="includeTransactionFee"
										isChecked={input.includeTransactionFee}
										onChange={handleTransactionFeeChange}
										size="lg"
										justifySelf={"center"}
									>
										Include Transaction Fee
									</Checkbox>
								</FormControl>
							</Stack>
							<FormControl 
								id="pdf" 
							>
								<FormLabel>Taylor Fence Invoice PDF</FormLabel>
								<PDFDropzone
									pdfFile={pdf}
									setPDFFile={handleSetPDF}
								/>
							</FormControl>
							<Stack 
								spacing={4}
								borderRadius="md"
								borderWidth="1px"
								borderColor="gray.300"
							>
								{input.items.map((item, index) => (
									<Stack 
										key={index} 
										direction={{
											base: "column",
											md: "row",
										}} 
										spacing={5}
										align="center"
										justify="center"
										borderBottomWidth="1px"
										borderBottomColor="gray.300"
										p={4}

									>
										<FormControl>
											<FormLabel>Item</FormLabel>
											<Input
												name="item"
												value={item.item}
												onChange={(e) => handleUpdateItem(e, index)}
												borderRadius="md"
												borderWidth="1px"
												borderColor="gray.300"
												boxShadow="md"
											/>
										</FormControl>
										<FormControl>
											<FormLabel>Quantity</FormLabel>
											<Input
												type="number"
												name="quantity"
												value={item.quantity}
												onChange={(e) => handleUpdateItem(e, index)}
												borderRadius="md"
												borderWidth="1px"
												borderColor="gray.300"
												boxShadow="md"
											/>
										</FormControl>
										<FormControl>
											<FormLabel>Rate</FormLabel>
											<Input
												type="number"
												name="rate"
												value={item.rate}
												onChange={(e) => handleUpdateItem(e, index)}
												borderRadius="md"
												borderWidth="1px"
												borderColor="gray.300"
												boxShadow="md"
											/>
										</FormControl>
										<Flex
											align="center"
											justify="center"	
										>
											<CheckboxGroup defaultValue={[]}>										
												<Checkbox
													variant="primary"
            							isChecked={item.isTaxable}
           								onChange={(e) => handleTaxableChange(e.target.checked, index)}
													size= "md"
													mr={4}
													mt={6}
													align="center"
													justify="center"
       					 			>		
           								 Apply Tax
        							</Checkbox>
											</CheckboxGroup>
											<IconButton 
												onClick={() => handleRemoveItem(index)}
												borderRadius="md"
												borderWidth="1px"
												borderColor="gray.300"
												boxShadow="sm"
												icon={<DeleteIcon />}
												p={4}
												mt={8}
											>
											Remove
											</IconButton>
										</Flex>
									</Stack>
								))}
							</Stack>
							<Stack
								direction={{
									base: "column",
									md: "row",
								}}
								spacing={4}
								justifyContent="flex-end"
								alignItems={"center"}
							>
								<Button 
									onClick={handleAddNewItem}
									borderRadius="md"
									borderWidth="1px"
									borderColor="gray.300"
									boxShadow="sm"
									p={2}
									_hover={{
										transform: "scale(1.01)",
										cursor: "pointer",
										bg: "gray.300",
									}}
									w="fit-content"
								>
									Add New Item
								</Button>
								<Button 
									onClick={() => setShowSelectFromProducts(!showSelectFromProducts)}
									borderRadius="md"
									borderWidth="1px"
									borderColor="gray.300"
									boxShadow="sm"
									p={2}
									_hover={{
										transform: "scale(1.01)",
										cursor: "pointer",
										bg: "gray.300",
									}}
									w="fit-content"
								>
									{showSelectFromProducts ? "Hide" : "Show"} Products
								</Button>
							</Stack>
							<Collapse in={showSelectFromProducts}>
								<Stack 
									spacing={4}
									p={4}
								>
									<Stack
										direction={"row"}
										w="100%"
									>
										<Input
											placeholder="Search Products"
											defaultValue={searchTerm}
											w="70%"
											mr="5%"
											id={"searchTermCreateInvoice"}
											onKeyDown={(e) => {
												if (e.key === "Enter") {
													let term = document.getElementById("searchTermCreateInvoice").value;
													setSearchTerm(term);
													setOffset(0);
												}
											}}
										/>
										<Button
											variant="primary"
											onClick={() => {
												let term = document.getElementById("searchTermCreateInvoice").value;
												setSearchTerm(term);
												setOffset(0);
											}}
											w="25%"
										>
											Search
										</Button>
									</Stack>
									<Stack spacing={4}>
										{paginatedProducts?.items?.map((product, index) => (
											<ProductDisplay
												key={index}
												product={product}
												setSelectedProduct={setSelectedProduct}
											/>
										))}
									</Stack>
									<BackendPagination
										limit={limit}
										setLimit={setLimit}
										offset={offset}
										setOffset={setOffset}
										totalItems={paginatedProducts?.totalItems}
										hasNextPage={paginatedProducts?.hasNextPage}
										hasPreviousPage={paginatedProducts?.hasPreviousPage}
									/>					
								</Stack>
							</Collapse>
						</Stack>
						<Stack direction="row" spacing={4} mt={4}>
							<Button
								variant="primary"
								isLoading={createLoading}
								type="submit"
							>
    							Create Invoice
							</Button>
							<Button onClick={handleClose}>Cancel</Button>
						</Stack>
					</LoadingErrorDataWrapper>
				</ModalBody>
				<ModalFooter>
					{createError && (
						<Alert status="error" mt={4}>
							<AlertIcon />
							<AlertDescription>{createError}</AlertDescription>
						</Alert>
					)}
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

const ProductDisplay = ({ product, setSelectedProduct }) => {

	const [showVariations, setShowVariations] = useState(false);


	const handleSetSelectedProduct = (selectedVariant, selectedPrice, index) => {
		setSelectedProduct({
			name: (product.name + " Variant " + (index+1)),
			...selectedVariant,
			price: selectedPrice,
		});
	};

	return (
		<Flex
			direction="column"
			borderWidth="1px"
			borderColor="gray.400"
			borderRadius="lg"
			boxShadow="md"
			w="100%"
			px={{
				base: "1rem",
				md: "2rem",
			}}
		>
			<Stack
				direction={[ "column", "row" ]}
				spacing={4}
				py={{
					base: "1rem",
					md: "0.75rem",
				}}
				px={{
					base: "1rem",
					md: "2rem",
				}}
			>
				<TextCell
					fontWeight="bold"
					fontSize="xl"
				>
					{product.name}
				</TextCell>
				<TableCell
					w="fit-content"
				>
					<Button
						p={5}
						variant="primary"
						onClick={(e) => {
							e.stopPropagation();
							setShowVariations(!showVariations);
						}}
						borderRadius="md"
						borderWidth="1px"
						borderColor="gray.300"
						boxShadow="sm"
						_hover={{
							transform: "scale(1.01)",
							cursor: "pointer",
							bg: "gray.400",
						}}
						
					>
						{showVariations ? "Hide" : "Show"} Variations
					</Button>
				</TableCell>
			</Stack>
			<Collapse 
				in={showVariations}
				animateOpacity
				style={{
					width: "100%",
				}}
			>
				<Stack
					direction="column"
					p={4}
					mt={6}
					spacing={6}
				>
					{
						product.variations?.map((variation, index) => (
							<Flex
								key={index}
								direction="column"
								p={4}
								borderWidth="1px"
								borderColor="gray.400"
								borderRadius="lg"
								boxShadow="md"
								_hover={{
									transform: "scale(1.01)", 
									borderColor: "gray.600",
									boxShadow: "lg",
								}}
							>
								<TextCell>Variation {index+1}</TextCell>
								<TextCell>SKU: {variation.sku}</TextCell>
								
								{variation.options && Object.keys(variation?.options)?.map((option, index) => (
									<TextCell key={index}>{option}: {variation.options[option]}</TextCell>
								))}
								<Stack
									w="100%"
									alignItems="flex-start"
									justifyContent="flex-start"
									direction="column"
									spacing={2}
								>
									<Text>Prices:</Text>
									<Stack
										w="100%"
										alignItems="flex-start"
										justifyContent="flex-start"
										direction="column"
										spacing={2}
									>
										{variation?.price && Object.keys(variation?.price).map((key, idx) => (
											<Stack
												direction="row"
												key={idx}
												gap={2}
												onClick={() => handleSetSelectedProduct(variation, variation.price[key], index)}
												//Add border and hover
												p="1rem"
												border="1px solid gray"
												_hover={
													{
														cursor: "pointer",
														transform: "scale(1.01)",
														borderColor: "gray.600",
														boxShadow: "lg",
													}
												}
											>
												<Text>{key}: </Text>
												<Text>{variation.price[key]} </Text>
											</Stack>
										))}	
									</Stack> 
								</Stack>
							</Flex>
						))
					}
				</Stack>
			</Collapse>				
		</Flex>
	);
};


export default CreateInvoice;