/**
 * @file CancelInvoice.js is responsible for rendering the cancel invoice modal
 */
import React, { useState } from "react";

import {
	Button,
	Modal,
	ModalOverlay,
	ModalContent, 
	ModalHeader, 
	ModalFooter, 
	ModalBody, 
	ModalCloseButton,
	Text,
	Input,
} from "@chakra-ui/react";

import ErrorMsg from "Blueprints/ErrorMsg";

import useCancelInvoice from "hooks/Invoice/mutations/useCancelInvoice";

/**
 * @function CancelInvoice
 * 
 * @summary - Modal to cancel an invoice
 */
const CancelInvoice = ({ isOpen, onClose, invoice, refetch }) => {
	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);

	const [confirmText, setConfirmText] = useState("");

	const { cancelInvoice } = useCancelInvoice();

	/**
	 * @function handleCancelInvoice
	 * 
	 * @description - This function is used to cancel an invoice from the database.
	 */
	const handleCancelInvoice = async (e) => {
		e.stopPropagation();
		e.preventDefault();

		setLoading(true);

		let response = await cancelInvoice(invoice._id);
		if(response.error) {
			setErrorMsg(response.error.message);
			setLoading(false);
			return;
		}
		if(response.data) {
			refetch();
			onClose();
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Cancel Invoice</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text mb={3}>
						Are you sure you want to cancel this invoice?
					</Text>
					<Text mb={3}>
						Please type <strong>CANCEL</strong> to confirm.
					</Text>
					<Input
						placeholder="Type CANCEL to confirm"
						value={confirmText}
						onChange={(e) => setConfirmText(e.target.value)}
					/>
					{errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}

				</ModalBody>

				<ModalFooter>
					<Button
						variant="primary"
						mr={3}
						isLoading={loading}
						onClick={handleCancelInvoice}
						isDisabled={confirmText !== "CANCEL"}
					>
						Cancel Invoice
					</Button>
					<Button variant="ghost" onClick={onClose}>
						Cancel
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default CancelInvoice;

							
