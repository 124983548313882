/* eslint-disable no-unused-vars */
/**
 * @file - Table.js
 */
import React, { useEffect, useState } from "react";

import {
	Text,
	Flex,
	useBreakpointValue,
	Button,
	Link,
	useToast,
} from "@chakra-ui/react";

import { TableContainer, HeaderContainer, RowContainer } from "../../components/TableContainer";
import { TextCell, TableCell } from "../../components/TableCell";

import CancelInvoice from "./modals/CancelInvoice";
import RefundInvoice from "./modals/RefundInvoice";
import SendInvoice from "./modals/SendInvoice";

import useGetStripeInvoiceLink from "hooks/Invoice/queries/useGetStripeInvoiceLink";
import useCheckInvoiceStatus from "hooks/Invoice/mutations/useCheckInvoiceStatus";

import { convertDateToReadableFormat, displayDollarAmount } from "utils/utils";

/**
 * @component InvoiceRow
 */
const InvoiceRow = (props) => {
	const invoice = props.invoice;

	const { loading, error, data } = useGetStripeInvoiceLink(invoice._id);

	const isCancellable = invoice.status === "pending";
	const isRefundable = invoice.status === "paid";
	const isSendable = invoice.status !== "cancelled";

	const [showCancelInvoice, setShowCancelInvoice] = useState(false);
	const openCancelInvoiceModal = (e) => {
		e.stopPropagation();
		setShowCancelInvoice(true);
	};
	const closeCancelInvoiceModal = () => setShowCancelInvoice(false);

	const [showRefundInvoice, setShowRefundInvoice] = useState(false);
	const openRefundInvoiceModal = (e) => {
		e.stopPropagation();
		setShowRefundInvoice(true);
	};
	const closeRefundInvoiceModal = () => setShowRefundInvoice(false);

	const [showSendInvoice, setShowSendInvoice] = useState(false);
	const openSendInvoiceModal = (e) => {
		e.stopPropagation();
		setShowSendInvoice(true);
	};
	const closeSendInvoiceModal = () => setShowSendInvoice(false);


	const { checkInvoiceStatus } = useCheckInvoiceStatus();

	const toast = useToast();

	const [checkingStatus, setCheckingStatus] = useState(false);
	const handleCheckInvoiceStatus = async (e) => {
		e.stopPropagation();
		setCheckingStatus(true);

		const { error, data } = await checkInvoiceStatus(invoice._id);
		if(error){
			toast({
				title: "Error",
				description: error.message,
				status: "error",
				duration: 5000,
				isClosable: true,
				position: "top",
			});
		}
		if(data){
			let updated = data.includes("updated");
			if(updated){
				toast({
					title: "Updated",
					description: data,
					status: "success",
					duration: 5000,
					isClosable: true,
					position: "top",
				});
				props.invoicesRefetch();
			}
			else{
				toast({
					title: "No Change",
					description: data,
					status: "info",
					duration: 5000,
					isClosable: true,
					position: "top",
				});
			}
		}
		setCheckingStatus(false);
	};

	return (
		<RowContainer>
			<TextCell>
				{invoice._id}
			</TextCell>
			<TextCell>
				{`${invoice.customer.name}`}
			</TextCell>
			<TextCell>
				{invoice.status}
			</TextCell>
			<TextCell>
				{displayDollarAmount(invoice.total_amount)}
			</TextCell>
			<TableCell>
				<Link
					isExternal
					href={data?.url}
				>
					<Button
						variant="primary"
						isLoading={loading}
						loadingText="Loading..."
						isDisabled={error || !data}
						w="100%"
					>
						{(!data || error) ? "Error" : "View"}
					</Button>
				</Link>
				{isSendable && 
					<Button
						variant="primary"
						onClick={openSendInvoiceModal}
					>
						Send
					</Button>
				}
				{isCancellable && <Button
					variant="primary"
					onClick={openCancelInvoiceModal}
				>
					Cancel
				</Button>}
				{
					isRefundable && <Button
						variant="primary"
						onClick={openRefundInvoiceModal}
					>
						Refund
					</Button>
				}
				<Button
					variant="primary"
					onClick={handleCheckInvoiceStatus}
					isLoading={checkingStatus}
					//Make sure text doesn't overflow
					whiteSpace="nowrap"
					flexWrap={{ base: "wrap", lg: "nowrap" }}
				>
					Check Status
				</Button>
			</TableCell>
			<CancelInvoice
				isOpen={showCancelInvoice}
				onClose={closeCancelInvoiceModal}
				invoice={invoice}
				refetch={props.invoicesRefetch}
			/>
			<RefundInvoice
				isOpen={showRefundInvoice}
				onClose={closeRefundInvoiceModal}
				invoice={invoice}
				refetch={props.invoicesRefetch}
			/>
			<SendInvoice
				isOpen={showSendInvoice}
				onClose={closeSendInvoiceModal}
				invoice={invoice}
				refetch={props.invoicesRefetch}
			/>
		</RowContainer>
	  );
};


/**
 * @component InvoiceTable
 * @param {*} props
 * @returns
 */
const InvoiceTable = (props) => {
	const [invoices, setInvoices] = useState(props.invoices);

	useEffect(() => {
		if(props.invoices) setInvoices(props.invoices);
	}, [props.invoices]);

	return (
		<TableContainer>
			<HeaderContainer>
				<TextCell
					heading={true}
					sort={(order) => props.sort("_id", order)}
					search={(value) => props.search("_id", value)}
					clearToggle={props.clearToggle}
				>
					Invoice ID
				</TextCell>
				<TextCell
					heading={true}
					sort={(order) => props.sort("customer", order)}
					search={(value) => props.search("customer", value)}
					clearToggle={props.clearToggle}
				>
					Customer (Name/Company)
				</TextCell>
				<TextCell
					heading={true}
					sort={(order) => props.sort("status", order)}
					search={(value) => props.search("status", value)}
					clearToggle={props.clearToggle}
				>
					Status
				</TextCell>
				<TextCell
					heading={true}
					sort={(order) => props.sort("total_amount", order)}
					search={(value) => props.search("total_amount", value)}
					clearToggle={props.clearToggle}
				>
					Total Amount
				</TextCell>
				<TextCell>
					Actions
				</TextCell>
			</HeaderContainer>
			{invoices.map((invoice, index) => (
				<InvoiceRow
					key={index}
					invoice={invoice}
					invoicesRefetch={props.invoicesRefetch}
				/>
			))}
		</TableContainer>
	);
};

export default InvoiceTable;